import { type, isNil, isEmpty } from "ramda"

const storages = {
  local: window.localStorage,
  session: window.sessionStorage,
}

/**
 * Retrieve value by key from local/session storage
 *
 * @param  {string}   key         The key
 * @param  {Object}   arg2        The argument 2
 * @param  {boolean}  arg2.local  If true will get from localStorage, else from
 *                                session storage
 * @return {string|undefined}
 */
export const find = (key) => {
  const namespacedKey = `${WEBP_STORAGE_NAMESPACE}_${key}`
  const storage = Object.values(storages).find(
    (_storage) => _storage.getItem && _storage.getItem(namespacedKey) !== null,
  )

  return storage ? storage.getItem(namespacedKey) : undefined
}

/**
 * Check if key exists in any storage
 *
 * @param  {string}   key  The key
 *
 * @return {boolean}  True if has key, False otherwise.
 */
export const has = (key) => !isNil(find(key)) && !isEmpty(find(key))

/**
 * Save value by key to local/session storage
 *
 * @param  {Object|Array}  data        Key/Value pair
 * @param  {Object}        arg2        Options
 * @param  {string}        arg2.local  Whether to use localStorage instead of
 *                                     session storage
 *
 * @return {undefined}     { description_of_the_return_value }
 */
export const upsert = (data, { local = true } = {}) => {
  const items = type(data) === "Array" ? data : [data]
  const storage = local ? storages.local : storages.session

  items.forEach((item) => {
    storage.setItem(`${WEBP_STORAGE_NAMESPACE}_${item.key}`, item.value)
  })
}

/**
 * Removes key from all storages
 *
 * @param  {string}  key  The key
 *
 * @return {undefined}
 */
export const remove = (key) => {
  const namespacedKey = `${WEBP_STORAGE_NAMESPACE}_${key}`

  Object.values(storages).forEach((storage) =>
    storage.removeItem(namespacedKey),
  )
}

/**
 * Clears all storages
 *
 * @param  {Object}     arg1          Options
 * @param  {boolean}    arg1.local    Whether to clear localStorage
 * @param  {boolean}    arg1.session  Whether to clear sessionStorage
 *
 * @return {undefined}
 */
export const removeAll = ({ local = true, session = true } = {}) => {
  local && storages.local.clear()
  session && storages.session.clear()
}
