import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { hasToken, clearSession } from "lib/core/session.api"

import {
  getInvitation,
  acceptInvitation,
} from "entities/schools/schools.actions"
import { login } from "entities/sessions/sessions.actions"

import { AccountInviteView } from "entities/account__invite.page/account__invite.view"

export class PureAccountInvitePage extends React.Component {
  static propTypes = {
    errors: PropTypes.object,
    invitation: PropTypes.object,
    isInvitationLoading: PropTypes.bool.isRequired,
    isInvitationAccepted: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    errors: {},
    invitation: {},
    isInvitationLoading: true,
  }

  componentDidMount = () => {
    const { invitation, match, getInvitation } = this.props
    const invitationKey = match.params.invitationKey

    if (hasToken()) {
      clearSession()
    }

    if (!(invitation && invitation.id)) {
      getInvitation(invitationKey)
    }
  }

  render() {
    const { errors, invitation, match, acceptInvitation, login } = this.props

    return invitation && invitation.id ? (
      <AccountInviteView
        errors={errors}
        inviteKey={match.params.invitationKey}
        inviteID={invitation.id}
        inviteEmail={invitation.email}
        isInviteAccepted={invitation.accepted}
        handleInvitationAccept={acceptInvitation}
        handleLogin={login}
      />
    ) : null
  }
}

const connected = withRouter(
  connect(
    (state) => ({
      errors: state.school.invitationErrors,
      invitation: state.school.invitation,
      isInvitationLoading: state.school.invitationLoading,
      isInvitationAccepted: state.school.invitation.accepted || false,
    }),
    { getInvitation, acceptInvitation, login },
  )(PureAccountInvitePage),
)

export default connected
