import { translateWith } from "lib/core/i18n/i18n.utils"
import { languagePack as rootLanguagePack } from "lib/core/i18n/root.i18"

export const languagePack = {
  ["sidemenu__school"]: {
    nl: "School",
    en: "School",
  },
  ["sidemenu__grades"]: {
    nl: "Leerjaar",
    en: "Grades",
  },
  ["sidemenu__groups"]: {
    nl: "Groepen",
    en: "Groups",
  },
  ["sidemenu__groups--worksheet"]: {
    nl: "Werkblad",
    en: "Worksheet",
  },
  ["sidemenu__groups--results"]: {
    nl: "Resultaten",
    en: "Results",
  },
  ["sidemenu__groups--goals"]: {
    nl: "Toetsoverzicht",
    en: "Test overview",
  },
  ["sidemenu__pupils"]: {
    nl: "Leerling",
    en: "Pupils",
  },
  ["sidemenu__admin"]: {
    nl: "Beheer",
    en: "Management",
  },
  ["sidemenu__admin--templates"]: {
    nl: "Plannen",
    en: "Templates",
  },
  ["sidemenu__admin--permissions"]: {
    nl: "Gebruikers",
    en: "Permissions",
  },
  ["sidemenu__admin--pupils"]: {
    nl: "Leerlingen",
    en: "Pupils",
  },
  ["sidemenu__admin--upload"]: {
    nl: "Uploaden",
    en: "Upload",
  },
}

export const t = translateWith(languagePack, rootLanguagePack)
