import React from "react"
import cx from "classnames"
import Select from "react-select"
import Creatable from "react-select/creatable"
import { isEmpty, isNil } from "ramda"
import { hasWith } from "@leeruniek/functies"
import { DropdownIndicator } from "components/select/dropdown-indicator"
import { Input } from "components/select/select-input"
import { ALL_OPTION_VALUE, NONE_OPTION_VALUE } from "lib/leeruniek/constants"

import css from "components/select/css/select.module.scss"
import { t } from "lib/core/i18n/root.i18"

const defaultThemeStyles = {
  control: (provided, state) => {
    const isFocusedStyle = state.isFocused
      ? {
          borderBottom: "1px solid rgb(38, 166, 154)",
          boxShadow: "none",
        }
      : {}

    return {
      ...provided,
      borderTop: 0,
      borderRight: 0,
      borderLeft: 0,
      borderRadius: 0,
      borderBottom: "1px solid #e0e0e0",
      ...isFocusedStyle,
      "&:hover": {
        boxShadow: "none",
      },
    }
  },
  menuList: (provided) => ({
    ...provided,
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    borderRadius: 0,
    boxShadow: "none",
  }),
  menu: (provided) => ({
    ...provided,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 0,
    boxShadow: "none",
  }),
  multiValue: (provided) => ({
    ...provided,
    border: "1px solid rgba(0,126,255,0.24)",
    backgroundColor: "rgba(0,126,255,0.08)",
    padding: "2px",
    borderRadius: "3px",
    color: "#676a6c",
    fontSize: "1.1em",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: "3px 6px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderLeft: `1px solid rgba(0,126,255,0.24)`,
    padding: "0px 5px",
    "&:hover": {
      backgroundColor: `rgba(0,113,230,0.08)`,
      color: "#676a6c",
    },
  }),
  clearIndicator: () => ({
    display: "none",
  }),
  indicatorsContainer: () => ({
    paddingRight: 0,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
}

const yellowThemeStyles = {
  option: (provided, state) => {
    const isSelectedStyle = state.isSelected
      ? {
          color: "#333",
          backgroundColor: "rgba(245, 166, 35, 0.12)",
        }
      : {}
    const isFocusedStyle = state.isFocused
      ? {
          color: "#333",
          backgroundColor: "rgba(245, 166, 35, 0.12)",
        }
      : {}

    return {
      ...provided,
      ...isFocusedStyle,
      ...isSelectedStyle,
    }
  },
}

class LUSelect extends React.PureComponent {
  // Default props values for non-required props
  static defaultProps = {
    className: null,
    label: null,
    placeholder: "",
    inputPlaceholder: "",
    value: null,
    defaultValue: null,
    color: "",
    "data-testid": "lu_select",

    noResultsText: "No results found",
    promptTextCreator: null,
    optionRenderer: null,

    autoFocus: false,
    isMulti: false,
    hasToggleAll: false,
    isLoading: false,
    isClearable: false,
    isCreatable: false,
    closeMenuOnSelect: true,
    isMenuOpenOnFocus: false,
    isDisabled: false,

    minMenuHeight: 50,
    maxMenuHeight: 300,

    onChange: () => {},
    onFocus: null,
    onBlur: null,
    onInputChange: null,
  }

  /**
   * When called, it should examine this.props and this.state and return a
   * single React element. This element can be either a representation of a
   * native DOM component, such as <div />, or another composite component
   * that you've defined yourself.
   *
   * @return {Component}
   */
  render() {
    const {
      className,
      classNamePrefix,
      label,
      color,
      autoFocus,
      isCreatable,
      value,
      options,
      promptTextCreator,
      optionRenderer,
      noResultsText,
      placeholder,
      inputPlaceholder,
      inputValue,
      isLoading,
      isClearable,
      isDisabled,
      isMenuOpenOnFocus,
      closeMenuOnSelect,
      isMulti,
      hasToggleAll,
      onInputChange,
      providedStyles,
      components,
      minMenuHeight,
      maxMenuHeight,
    } = this.props

    const SelectType = isCreatable ? Creatable : Select

    return (
      <div
        className={cx(css.select, {
          [className || ""]: !isNil(className),
          [css["select--disabled"]]: isDisabled,
          [css["select--focused"]]: autoFocus,
          [css["select-has-value"]]: !isEmpty(value),
          [css[color]]: !isEmpty(color),
        })}
        data-testid={this.props["data-testid"]}>
        {!isNil(label) ? (
          <label className={css.label} onClick={this.handleLabelClick}>
            {label}
          </label>
        ) : null}

        <SelectType
          components={{ DropdownIndicator, Input, ...components }}
          defaultValue={{ label: 2021, value: 2021 }}
          value={value}
          isMulti={isMulti}
          options={
            hasToggleAll && isMulti
              ? [
                  value.length === options.length
                    ? {
                        value: NONE_OPTION_VALUE,
                        label: `- ${t("none")} -`,
                      }
                    : {
                        value: ALL_OPTION_VALUE,
                        label: `- ${t("all")} -`,
                      },
                  ...options,
                ]
              : options
          }
          className={css["inner-select"]}
          classNamePrefix={classNamePrefix}
          autoFocus={autoFocus}
          openOnFocus={isMenuOpenOnFocus}
          isLoading={isLoading}
          closeMenuOnSelect={closeMenuOnSelect}
          placeholder={placeholder}
          inputValue={inputValue}
          inputPlaceholder={inputPlaceholder}
          noResultsText={noResultsText}
          optionRenderer={optionRenderer}
          allowCreate={isCreatable}
          clearable={isClearable}
          promptTextCreator={promptTextCreator}
          onBlur={this.handleSelectBlur}
          onFocus={this.handleSelectFocus}
          onInputChange={onInputChange}
          onChange={this.handleOnChange}
          minMenuHeight={minMenuHeight}
          maxMenuHeight={maxMenuHeight}
          styles={
            isEmpty(color)
              ? { ...defaultThemeStyles, ...providedStyles }
              : {
                  ...defaultThemeStyles,
                  ...yellowThemeStyles,
                  ...providedStyles,
                }
          }
        />
      </div>
    )
  }

  handleOnChange = (currentValues) => {
    const { options, onChange, isMulti } = this.props

    const byType = {
      array: () => {
        if (
          currentValues === null ||
          hasWith({ value: NONE_OPTION_VALUE })(currentValues)
        ) {
          return []
        }
        if (hasWith({ value: ALL_OPTION_VALUE })(currentValues)) {
          return options
        }

        return currentValues
      },
      other: () => currentValues,
    }

    onChange(
      Array.isArray(currentValues) || (isMulti && currentValues === null)
        ? byType.array()
        : byType.other(),
    )
  }

  /**
   * { function_description }
   *
   * @return {undefined}
   */
  handleLabelClick = () => {
    if (!isNil(this._selectInstance)) {
      this._selectInstance.focus()
    }
  }

  /**
   * { function_description }
   *
   * @return {undefined}
   */
  handleSelectBlur = () => {
    const { onBlur } = this.props

    if (!isNil(onBlur)) {
      onBlur()
    }
  }

  /**
   * { function_description }
   *
   * @return {undefined}
   */
  handleSelectFocus = () => {
    const { onFocus } = this.props

    if (!isNil(onFocus)) {
      onFocus()
    }
  }

  /**
   * { lambda_description }
   *
   * @param  {Component}  ref  The Select component reference
   *
   * @return {undefined}
   */
  handleSelectRef = (ref) => {
    this._selectInstance = ref
  }
}

export { LUSelect }
