import { createReducer } from "@reduxjs/toolkit"
import { uniqWith, isNil, sort } from "ramda"
import { YEAR_TIMES } from "lib/leeruniek/constants"
import {
  updateInvitation,
  updateSchoolSenco,
  removeSchoolSenco,
} from "./schools.actions"

export const initialState = {
  school: {},
  schoolLoading: true,
  history: {
    notes: [],
    isSchoolNoteLoading: false,
  },
  historyLoading: true,
  pupils: [],
  pupilsLoading: true,
  sencos: [],
  sencosError: null,
  sencosLoading: true,
  invitation: {},
  invitationIdToRemove: null,
  invitationSuccesses: [],
  invitationErrors: {},
  invitationLoading: true,
  schoolAmbitions: {
    isSaving: false,
    ambitions: [],
  },
  testProviders: {
    isLoading: false,
    providers: [],
  },
  nationalTests: {
    isLoading: false,
    nationalTests: [],
  },
  schoolOutflow: {
    data: {},
    isLoading: false,
  },
  removedPrintSectionCount: 0,
}

// Turn a school history into a list of exams
const makeExams = ({ scores, years }) =>
  years
    .reduce((acc, year) => {
      for (const yearTime of [
        YEAR_TIMES.COVID,
        YEAR_TIMES.END,
        YEAR_TIMES.MIDDLE,
      ]) {
        if (
          scores.some(
            (scoresByType) =>
              !isNil(
                scoresByType.averageScoresPerSchool.find(
                  (avr) => avr.yearTime === yearTime && avr.year === year,
                ),
              ),
          )
        ) {
          acc.push({ year, yearTime })
        }
      }

      return acc
    }, [])
    .sort((a, b) => {
      if (a.year === b.year) {
        return b.yearTime.localeCompare(a.yearTime)
      }

      return a.year.localeCompare(b.year)
    })

export default createReducer(initialState, (builder) => {
  builder
    .addCase("GET_SCHOOL_REQUEST", (state) => ({
      ...state,
      schoolLoading: true,
    }))
    .addCase("GET_SCHOOL_SUCCESS", (state, action) => {
      return {
        ...state,
        school: action.payload,
        schoolLoading: false,
      }
    })
    .addCase("GET_SCHOOL_FAILURE", (state, action) => {
      return {
        ...state,
        schoolLoading: false,
        error: action.payload,
      }
    })
    .addCase("GET_SCHOOL_HISTORY_REQUEST", (state) => ({
      ...state,
      historyLoading: true,
    }))
    .addCase("GET_SCHOOL_HISTORY_SUCCESS", (state, action) => {
      return {
        ...state,
        history: {
          ...state.history,
          ...action.payload,
          subjects: sort(
            (a, b) => a.position - b.position,
            action.payload.subjects,
          ),
          exams: makeExams(action.payload),
        },
        schoolAmbitions: {
          ...state.schoolAmbitions,
          ambitions: action.payload.schoolAmbitions,
        },
        historyLoading: false,
      }
    })
    .addCase("GET_SCHOOL_HISTORY_FAILURE", (state) => ({
      ...state,
      historyLoading: false,
    }))
    .addCase("GET_SCHOOL_SENCOS_REQUEST", (state) => ({
      ...state,
      sencosLoading: true,
    }))
    .addCase("GET_SCHOOL_SENCOS_FAILURE", (state) => ({
      ...state,
      sencosLoading: false,
    }))
    .addCase("GET_SCHOOL_SENCOS_SUCCESS", (state, action) => ({
      ...state,
      sencos: action.payload,
      sencosLoading: false,
    }))
    .addCase(removeSchoolSenco.pending, (state) => ({
      ...state,
      sencosLoading: true,
    }))
    .addCase(removeSchoolSenco.fulfilled, (state, action) => ({
      ...state,
      sencos: state.sencos.filter(
        (senco) => senco.id !== action.meta.arg.sencoId,
      ),
      sencosLoading: false,
    }))
    .addCase(removeSchoolSenco.rejected, (state) => ({
      ...state,
      sencosLoading: false,
    }))
    .addCase("GET_SCHOOL_PUPILS_REQUEST", (state) => ({
      ...state,
      pupilsLoading: true,
    }))
    .addCase("GET_SCHOOL_PUPILS_SUCCESS", (state, action) => ({
      ...state,
      pupils: uniqWith(
        (a, b) => a.id === b.id,
        action.payload
          .map((yearclass) =>
            yearclass.pupils.map((pupil) => ({
              ...pupil,
              yearclassId: yearclass.id,
            })),
          )
          .flat(),
      ),
      pupilsLoading: false,
    }))
    .addCase("GET_SCHOOL_PUPILS_FAILURE", (state) => ({
      ...state,
      pupilsLoading: false,
    }))
    .addCase("GET_INVITATION_REQUEST", (state) => ({
      ...state,
      invitationLoading: true,
    }))
    .addCase("GET_INVITATION_SUCCESS", (state, action) => ({
      ...state,
      invitationLoading: false,
      invitation: action.payload,
    }))
    .addCase("GET_INVITATION_FAILURE", (state) => ({
      ...state,
      invitationLoading: false,
    }))
    .addCase("ACCEPT_INVITATION_REQUEST", (state) => ({
      ...state,
      acceptInvitationLoading: true,
    }))
    .addCase("ACCEPT_INVITATION_SUCCESS", (state) => ({
      ...state,
      acceptInvitationLoading: false,
      invitation: { ...state.invitation, accepted: true },
    }))
    .addCase("ACCEPT_INVITATION_FAILURE", (state, action) => ({
      ...state,
      acceptInvitationLoading: false,
      invitationErrors: action.payload.body,
    }))
    .addCase("CREATE_INVITATION_SUCCESS", (state, action) => ({
      ...state,
      sencos: [
        ...state.sencos,
        ...action.payload.invitations.map((invitation) => ({
          ...invitation,
          isInvitation: true,
        })),
      ],
      invitationSuccesses: action.payload.invitations.map(
        (invitation) => invitation.email,
      ),
      invitationErrors: action.payload.errors,
    }))
    .addCase("CREATE_INVITATION_FAILURE", (state, action) => ({
      ...state,
      invitationErrors: action.payload.errors,
    }))
    .addCase("REMOVE_INVITATION_REQUEST", (state, action) => ({
      ...state,
      // FIXME: we need to store this because the API doesn't return the id of a
      // removed invitation, figure out a more elegant way to do this or fix the API
      invitationIdToRemove: action.payload[1],
    }))
    .addCase("REMOVE_INVITATION_SUCCESS", (state) => ({
      ...state,
      sencos: state.sencos.filter(
        (senco) => senco.id !== state.invitationIdToRemove,
      ),
      invitationIdToRemove: null,
    }))
    .addCase("RESET_INVITATION_SUCCESSES_ERRORS", (state) => ({
      ...state,
      invitationSuccesses: [],
      invitationErrors: {},
    }))
    .addCase("SAVE_SCHOOL_AMBITIONS_REQUEST", (state) => ({
      ...state,
      schoolAmbitions: {
        ...state.schoolAmbitions,
        isSaving: true,
      },
    }))
    .addCase("SAVE_SCHOOL_AMBITIONS_SUCCESS", (state, action) => ({
      ...state,
      schoolAmbitions: {
        ambitions: [
          ...state.schoolAmbitions.ambitions.map((ambition) => {
            if (ambition.id === action.payload.id) {
              return action.payload
            }

            return ambition
          }),
        ],
        isSaving: false,
      },
    }))
    .addCase("GET_TEST_PROVIDERS_REQUEST", (state) => ({
      ...state,
      testProviders: {
        ...state.testProviders,
        isLoading: true,
      },
    }))
    .addCase("GET_TEST_PROVIDERS_SUCCESS", (state, action) => ({
      ...state,
      testProviders: {
        ...state.testProviders,
        providers: [...action.payload],
        isLoading: false,
      },
    }))
    .addCase("GET_TEST_PROVIDERS_FAILURE", (state) => ({
      ...state,
      testProviders: {
        ...state.testProviders,
        isLoading: false,
      },
    }))
    .addCase("GET_NATIONAL_TESTS_REQUEST", (state) => ({
      ...state,
      nationalTests: {
        ...state.nationalTests,
        isLoading: true,
      },
    }))
    .addCase("GET_NATIONAL_TESTS_SUCCESS", (state, action) => ({
      ...state,
      nationalTests: {
        ...state.nationalTests,
        nationalTests: [...action.payload],
        isLoading: false,
      },
    }))
    .addCase("GET_NATIONAL_TESTS_FAILURE", (state) => ({
      ...state,
      nationalTests: {
        ...state.nationalTests,
        isLoading: false,
      },
    }))
    .addCase("ADD_PUPIL_TO_BLACKLIST_SUCCESS", (state, action) => {
      const pupils = [...state.pupils]
      for (let i = 0; i < pupils.length; i++) {
        if (pupils[i]["id"] === action.payload.pupilId) {
          pupils[i] = { ...pupils[i], citoIsExcluded: true }
          break
        }
      }
      return { ...state, pupils: pupils }
    })
    .addCase("REMOVE_PUPIL_FROM_BLACKLIST_SUCCESS", (state, action) => {
      const pupils = [...state.pupils]
      for (let i = 0; i < pupils.length; i++) {
        if (pupils[i]["id"] === action.payload.pupilId) {
          pupils[i] = { ...pupils[i], citoIsExcluded: false }
          break
        }
      }
      return { ...state, pupils: pupils }
    })
    .addCase("GET_SCHOOL_OUTFLOW_LEVEL_REQUEST", (state) => ({
      ...state,
      schoolOutflow: {
        ...state.schoolOutflow,
        isLoading: true,
      },
    }))
    .addCase("GET_SCHOOL_OUTFLOW_LEVEL_SUCCESS", (state, action) => ({
      ...state,
      schoolOutflow: {
        data: action.payload,
        isLoading: false,
      },
    }))
    .addCase("GET_SCHOOL_OUTFLOW_LEVEL_FAILURE", (state) => ({
      ...state,
      schoolOutflow: {
        data: {},
        isLoading: false,
      },
    }))
    .addCase("CREATE_SCHOOL_NOTE_REQUEST", (state) => ({
      ...state,
      history: {
        ...state.history,
        isSchoolNoteLoading: true,
      },
    }))
    .addCase("CREATE_SCHOOL_NOTE_SUCCESS", (state, action) => ({
      ...state,
      history: {
        ...state.history,
        notes: [...state.history.notes, action.payload],
        isSchoolNoteLoading: false,
      },
    }))
    .addCase("UPDATE_SCHOOL_NOTE_REQUEST", (state) => ({
      ...state,
      history: {
        ...state.history,
        isSchoolNoteLoading: true,
      },
    }))
    .addCase("UPDATE_SCHOOL_NOTE_SUCCESS", (state, action) => {
      const notesToUpdate = [...state.history.notes]
      for (let i = 0; i < notesToUpdate.length; i++) {
        if (notesToUpdate[i]["id"] === action.payload.id) {
          notesToUpdate[i] = action.payload
          break
        }
      }
      return {
        ...state,
        history: {
          ...state.history,
          notes: notesToUpdate,
          isSchoolNoteLoading: false,
        },
      }
    })
    .addCase("DELETE_SCHOOL_NOTE_REQUEST", (state) => ({
      ...state,
      history: {
        ...state.history,
        isSchoolNoteLoading: true,
      },
    }))
    .addCase("DELETE_SCHOOL_NOTE_SUCCESS", (state, action) => ({
      ...state,
      history: {
        ...state.history,
        notes: [...state.history.notes].filter(
          (note) => note.id !== action.payload,
        ),
        isSchoolNoteLoading: false,
      },
    }))
    .addCase("INCREMENT_REMOVED_PRINT_SECTION_COUNT", (state) => {
      return {
        ...state,
        removedPrintSectionCount: state.removedPrintSectionCount + 1,
      }
    })
    .addCase("DECREMENT_REMOVED_PRINT_SECTION_COUNT", (state) => {
      return {
        ...state,
        removedPrintSectionCount:
          state.removedPrintSectionCount > 0
            ? state.removedPrintSectionCount - 1
            : 0,
      }
    })
    .addCase("RESET_REMOVED_PRINT_SECTION_COUNT", (state) => {
      return {
        ...state,
        removedPrintSectionCount: initialState.removedPrintSectionCount,
      }
    })
    .addCase(updateSchoolSenco.pending, (state) => ({
      ...state,
      sencosLoading: true,
    }))
    .addCase(updateSchoolSenco.fulfilled, (state, action) => ({
      ...state,
      school: action.meta.arg.isSencoSelf
        ? {
            ...state.school,
            isSchoolSuperuser: action.meta.arg.isSchoolSuperuser,
          }
        : { ...state.school },
      sencos: state.sencos.map((senco) =>
        senco.id === action.payload.id
          ? {
              ...action.payload,
              isInvitation: false,
            }
          : senco,
      ),
      sencosLoading: false,
    }))
    .addCase(updateSchoolSenco.rejected, (state, action) => ({
      ...state,
      sencosError: action.payload,
      sencosLoading: false,
    }))
    .addCase(updateInvitation.pending, (state, action) => ({
      ...state,
      sencosLoading: action.meta.arg.data.isSchoolSuperuser !== undefined,
    }))
    .addCase(updateInvitation.fulfilled, (state, action) => ({
      ...state,
      sencos: state.sencos.map((senco) =>
        senco.id === action.payload.id
          ? {
              ...action.payload,
              isInvitation: true,
            }
          : senco,
      ),
      sencosLoading: false,
    }))
    .addCase(updateInvitation.rejected, (state, action) => ({
      ...state,
      sencosError: action.payload,
      sencosLoading: false,
    }))
})
