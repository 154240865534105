import * as StorageAPI from "lib/core/storage.api"

const IS_DEMO = "is_demo"
const IS_IMPERSONATED = "is_impersonated"
const TOKEN_KEY = "token"

export const USERNAME_KEY = "username"

/**
 * Saves the username to localStorage
 *
 * @param  {string}  username  The username
 *
 * @return {undefined}
 */
export const saveUsername = (username) => {
  StorageAPI.upsert({
    key: USERNAME_KEY,
    value: username,
  })
}

/**
 * Determines if it has token.
 *
 * @return {boolean}  True if has token, False otherwise.
 */
export const hasToken = () => StorageAPI.has(TOKEN_KEY)

/**
 * Saves the user's JWT token
 *
 * @param  {Object}     arg1             Response object
 * @param  {string}     arg1.token       JWT
 * @param  {Object}     arg2             User login info
 * @param  {boolean}    arg2.rememberMe  The remember me
 * @param  {string}     arg2.username    The username
 *
 * @return {undefined}
 */
export const saveToken = ({ token }, { rememberMe, username }) => {
  const userKeys = [
    {
      key: TOKEN_KEY,
      value: token,
    },
    {
      key: USERNAME_KEY,
      value: username,
    },
  ]

  StorageAPI.upsert(userKeys, {
    local: rememberMe,
  })
}

/**
 * Gets the user's JWT token
 *
 * @return {string}
 */
export const getToken = () => StorageAPI.find(TOKEN_KEY)

/**
 * Remove all saved data from session/local storage
 *
 * @return {undefined}
 */
export const clearSession = () => {
  StorageAPI.removeAll()
}

/**
 * Determines if demo user.
 *
 * @return {boolean}  True if demo user, False otherwise.
 */
export const isDemoUser = () => StorageAPI.find(IS_DEMO) === "true"

/**
 * Determines if impersonated user.
 *
 * @return {boolean}  True if impersonated user, False otherwise.
 */
export const isImpersonatedUser = () =>
  StorageAPI.find(IS_IMPERSONATED) === "true"

/**
 * Saves user data.
 *
 * @param  {Object}  user  The user
 *
 * @return {undefined}
 */
export const saveUserData = (user) => {
  StorageAPI.upsert({
    key: IS_DEMO,
    value: user.isDemo,
  })
}
