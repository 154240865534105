import { createAction } from "@reduxjs/toolkit"
import { configureScope as sentryConfigureScope } from "@sentry/browser"

import { POST, DELETE, PATCH, GET, PUT } from "lib/core/api"
import { createAsyncAction } from "lib/core/redux.utils"
import {
  getTestProviders,
  getNationalTests,
} from "entities/schools/schools.actions"
import { getSubjects } from "entities/subjects/subjects.actions"
import { getMethods } from "entities/methods/methods.actions"
import * as SessionAPI from "lib/core/session.api"
import * as StorageAPI from "lib/core/storage.api"
import * as UsersAPI from "entities/users/users.api"
import * as RouterUtils from "lib/core/router.utils"

import { history, reduxStore } from "store"
import { isNil } from "ramda"
import { LOGIN_REDIRECT_STORAGE_KEY } from "lib/leeruniek/constants"
import { mixpanelTrack } from "lib/core/mixpanel"

const prepareDataForSentry = (userData) => {
  const userDataForSentry = JSON.parse(JSON.stringify(userData))

  return userDataForSentry
}

// Session action creators

export const login = createAsyncAction(
  "USER_LOGIN",
  async ({ username, password, rememberMe, multiFactorAuthAppCode }) => {
    const loginRedirect = StorageAPI.find(LOGIN_REDIRECT_STORAGE_KEY)
    SessionAPI.clearSession()
    if (!isNil(loginRedirect)) {
      StorageAPI.upsert({
        key: LOGIN_REDIRECT_STORAGE_KEY,
        value: loginRedirect,
      })
    }

    const loginResponse = await UsersAPI.login({
      username,
      password,
      multiFactorAuthAppCode,
    })

    if (loginResponse) {
      SessionAPI.saveToken(loginResponse, { username, rememberMe })
      mixpanelTrack("user-login", {
        result: "success",
        is_mfa_active: multiFactorAuthAppCode !== undefined,
      })

      return reduxStore.dispatch(loadUserProfile())
    }
  },
)

export const logout = createAction("SESSION_LOGOUT", () => {
  SessionAPI.clearSession()
  sentryConfigureScope((scope) => scope.setUser())
  history.push(RouterUtils.build("account__login"))

  return {
    payload: null,
  }
})

export const loadUserProfile = createAsyncAction(
  "LOAD_USER_PROFILE",
  async () => {
    if (!SessionAPI.hasToken()) {
      SessionAPI.clearSession()
      history.push("/auth/login")

      return Promise.reject(new Error("Some error"))
    }
    const userData = await UsersAPI.fetchCurrent()

    SessionAPI.saveUserData(userData)

    if (WEBP_ENV !== "development") {
      const trackingData = {
        isSchoolSuperuser: userData.isSchoolSuperuserOnAnySchool,
        isDemo: userData.isDemo,
        isRestricted: userData.isRestricted,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
      }
      try {
        mixpanel.identify(userData.id)
        mixpanel.people.set({
          $email: userData.email,
          commit_hash: WEBP_COMMIT_HASH,
          ...trackingData,
        })
      } catch (error) {
        console.log(error)
      }

      sentryConfigureScope((scope) =>
        scope.setUser(prepareDataForSentry(userData)),
      )
    }

    const [userSchool] = userData.schools

    const hasRedirect = StorageAPI.has(LOGIN_REDIRECT_STORAGE_KEY)
    const redirectParams = RouterUtils.getParams(
      StorageAPI.find(LOGIN_REDIRECT_STORAGE_KEY),
      "school",
    )

    const userSchoolId = redirectParams?.schoolId
      ? parseInt(redirectParams.schoolId, 10)
      : userSchool?.id

    reduxStore.dispatch(getTestProviders())
    reduxStore.dispatch(getNationalTests())
    reduxStore.dispatch(getSubjects())
    reduxStore.dispatch(getMethods())

    if (hasRedirect) {
      history.push(StorageAPI.find(LOGIN_REDIRECT_STORAGE_KEY))
      StorageAPI.remove(LOGIN_REDIRECT_STORAGE_KEY)
    } else {
      const homepageRoute =
        RouterUtils.HOMEPAGE_NAMES_TO_ROUTE_NAMES[userData.homepage.page] ||
        "schoolsList"
      const homepage = RouterUtils.build(homepageRoute, {
        schoolId: !isNil(userData.homepage.schoolId)
          ? userData.homepage.schoolId
          : userSchoolId,
        yearclassId: userData.homepage.groupId,
      })

      history.push(homepage)
    }

    return {
      user: userData,
      schools: userData.schools,
      schoolId: userSchoolId,
      isImpersonated: SessionAPI.isImpersonatedUser(),
    }
  },
)

export const changeSchool = createAction("CHANGE_SCHOOL", (school) => {
  const mixpanelGroupKey = "school"
  const mixpanelGroupId = `${school.brinCode} - ${school.name} (${school.id})`
  mixpanel.set_group(mixpanelGroupKey, mixpanelGroupId)

  mixpanel.get_group(mixpanelGroupKey, mixpanelGroupId).set({
    id: school.id,
    brin_code: school.brinCode,
    paying_status: school.payingStatus,
    school_name: school.name,
    start_year: school.startYear,
  })

  return {
    payload: school,
  }
})

export const changeSelectedSubject = createAction("CHANGE_SELECTED_SUBJECT")

export const changeSchoolYear = createAction("CHANGE_SCHOOL_YEAR")

export const setSetting = createAction("SET_SETTING")

// Account action creators

export const updateAccount = createAsyncAction(
  "SAVE_ACCOUNT_DETAILS",
  (data) => {
    const { name, email, username } = data
    const [firstName, ..._lastName] = name.split(" ")

    return PATCH("/rest-auth/user/", {
      email,
      firstName,
      lastName: _lastName.join(" "),
      username,
    })
  },
)

export const changePassword = createAsyncAction(
  "CHANGE_USER_PASSWORD",
  (data) => POST("/rest-auth/password/change/", data),
)

export const pinPupil = createAsyncAction("PIN_PUPIL", (id) =>
  POST("/pin_pupil/", { body: { pupil_id: id } }).then(() => id),
)

export const unpinPupil = createAsyncAction("UNPIN_PUPIL", (id) =>
  DELETE(`/pin_pupil/${id}/`).then(() => id),
)

export const toggleIsSchoolAmbitionVisible = createAction(
  "SET_SHOW_SCHOOL_AMBITION",
)

export const clearErrors = createAction("CLEAN_ERRORS")
export const trackClientUpdate = createAction("TRACK_CLIENT_UPDATE")

export const getUserSettings = createAsyncAction("GET_USER_SETTINGS", () =>
  GET("/user-settings/"),
)

export const createUserSettings = createAsyncAction(
  "CREATE_USER_SETTINGS",
  (input) => POST("/user-settings/create/", { ...input }),
)

export const updateUserSettings = createAsyncAction(
  "UPDATE_USER_SETTINGS",
  (input) => PUT("/user-settings/update/", { ...input }),
)
