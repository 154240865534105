import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { serializeError } from "lib/core/redux-tools.utils"

import { GET, POST, PATCH, DELETE } from "../../lib/core/api"
import { createAsyncAction } from "../../lib/core/redux.utils"

export const getSchool = createAsyncAction(
  "GET_SCHOOL",
  async (id) => {
    const response = await GET(`/schools/${id}/`)
    if (response && response.id && response.brinCode) {
      const trackingData = {
        schoolId: response.id,
        brinCode: response.brinCode,
      }
      try {
        mixpanel.people.set(trackingData)
      } catch (error) {
        console.log(error)
      }
    }
    return response
  },
  true,
)

export const getSchoolHistory = createAsyncAction(
  "GET_SCHOOL_HISTORY",
  (id, options) => GET(`/school_history/${id}/`, { query: options }),
)

export const getSchoolPupils = createAsyncAction("GET_SCHOOL_PUPILS", (id) =>
  GET(`/school_pupils/${id}/`),
)

export const getSchoolSencos = createAsyncAction("GET_SCHOOL_SENCOS", (id) =>
  GET(`/school_sencos/${id}/`),
)

export const removeSchoolSenco = createAsyncThunk(
  "REMOVE_SCHOOL_SENCO",
  ({ schoolId, sencoId }) =>
    POST("/school_sencos/remove/", { schoolId, sencoId }),
  { serializeError },
)

export const updateSchoolSenco = createAsyncThunk(
  "UPDATE_SCHOOL_SENCO",
  ({ schoolId, sencoId, isSchoolSuperuser }) =>
    PATCH(`/school_sencos/update_by_senco_id_and_school_id`, {
      schoolId,
      sencoId,
      isSchoolSuperuser,
    }),
  { serializeError },
)

export const getInvitation = createAsyncAction(
  "GET_INVITATION",
  (invitationKey) => GET(`/person_invitation/${invitationKey}`),
)

export const acceptInvitation = createAsyncAction(
  "ACCEPT_INVITATION",
  (invitationKey, userData) =>
    POST(`/person_invitation/${invitationKey}/accept/`, userData),
)

export const createInvitation = createAsyncAction(
  "CREATE_INVITATION",
  (schoolId, emails, isSchoolSuperuser) =>
    POST(`/person_invitation/`, { schoolId, emails, isSchoolSuperuser }),
)

export const removeInvitation = createAsyncAction(
  "REMOVE_INVITATION",
  (schoolId, invitationId) =>
    POST("/person_invitation/remove/", { invitationId }),
)

export const updateInvitation = createAsyncThunk(
  "UPDATE_INVITATION",
  ({ id, data }) => PATCH(`/person_invitation/${id}`, data),
  { serializeError },
)

export const resetInvitationSuccessesErrors = createAction(
  "RESET_INVITATION_SUCCESSES_ERRORS",
)

export const changeSchoolAmbitions = createAsyncAction(
  "SAVE_SCHOOL_AMBITIONS",
  (ambitionId, interval) =>
    PATCH(`/school_ambitions/${ambitionId}/`, {
      body: {
        id: ambitionId,
        lowScore: interval.min,
        highScore: interval.max,
      },
    }),
)

export const getTestProviders = createAsyncAction("GET_TEST_PROVIDERS", () =>
  GET("/test-providers/"),
)
export const getNationalTests = createAsyncAction("GET_NATIONAL_TESTS", () =>
  GET("/national-tests/"),
)

export const getSchoolOutflowLevel = createAsyncAction(
  "GET_SCHOOL_OUTFLOW_LEVEL",
  (id, options) => GET(`/school-outflow/${id}/`, { query: options }),
)

export const createSchoolNote = createAsyncAction(
  "CREATE_SCHOOL_NOTE",
  (school, subject, calendarYear, content, forwardLooking) =>
    POST("/school-notes/", {
      school,
      subject,
      calendarYear,
      content,
      forwardLooking,
    }),
)

export const updateSchoolNote = createAsyncAction(
  "UPDATE_SCHOOL_NOTE",
  (noteId, content) =>
    PATCH(`/school-notes/${noteId}`, {
      content,
    }),
)

export const deleteSchoolNote = createAsyncAction(
  "DELETE_SCHOOL_NOTE",
  (noteId) => DELETE(`/school-notes/${noteId}`).then(() => noteId),
)

export const incrementRemovedPrintSectionCount = createAction(
  "INCREMENT_REMOVED_PRINT_SECTION_COUNT",
)

export const decrementRemovedPrintSectionCount = createAction(
  "DECREMENT_REMOVED_PRINT_SECTION_COUNT",
)

export const resetRemovedPrintSectionCount = createAction(
  "RESET_REMOVED_PRINT_SECTION_COUNT",
)
